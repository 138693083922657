import Transaction from "../shared/model/transaction.js";

export const actionNames = {
  [Transaction.PaymentMethod.Prepayment]: {
    [Transaction.UserRole.Buyer]: {
      [Transaction.Status.AwaitingPayment]: "Maksa",
      [Transaction.Status.PickedUp]: ["Vahvista vastaanotetuksi", "Ilmoita ongelma"],
      //[Transaction.Status.ReadyForPickup]: "Vahvista vastaanotetuksi",
    },
    [Transaction.UserRole.Seller]: {
      [Transaction.Status.Paid]: "Odottaa ennakkomaksun hyväksyntää",
    },
  },
  [Transaction.PaymentMethod.Payment]: {
    [Transaction.UserRole.Buyer]: {
      [Transaction.Status.AwaitingPayment]: "Maksa",
      [Transaction.Status.PickedUp]: ["Vahvista vastaanotetuksi", "Ilmoita ongelma"],
      //[Transaction.Status.ReadyForPickup]: "Vahvista vastaanotetuksi",
    },
    [Transaction.UserRole.Seller]: {
      [Transaction.Status.Paid]: "Vahvista kauppa",
    },
  },

};

export const getActionName = (payment_method, status, role, receiver_id) => {
  if (receiver_id === null || receiver_id === undefined) {
    return null;
  }

  const action = actionNames?.[payment_method]?.[role]?.[status];
  return action;
};

export const formatCurrency = (currency) => {
  const currencies = {
    EUR: " €"
  };

  return currencies[currency] ?? currency;
};

export const formatPrice = (price, currency) =>
  isNaN(parseFloat(price)) ? "" : String(parseFloat(price).toFixed(2)) + formatCurrency(currency);

export const formatDate = (input) => {
  if (input === "" || input === undefined || input === null) {
    return "";
  }
  const date = input instanceof Date ? input : new Date(input);
  return `${date.toLocaleDateString("FI")} ${date.toLocaleTimeString("FI", {
    timeStyle: "short"    // without seconds
  })}`;
};
